import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginPageModule'
    },
    {
        path: 'dashboard-ipsos',
        loadChildren: './pages/dashboard-ipsos/dashboard-ipsos.module#DashboardIpsosPageModule'
    },
    {
        path: 'landing-page-ipsos',
        loadChildren: './pages/landing-page-ipsos/landing-page-ipsos.module#LandingPageIpsosModule'
    },
    {
        path: 'project-details',
        loadChildren: './pages/project-details/project-details.module#ProjectDetailsPageModule'
    },
    {
        path: 'project-list/:status',
        loadChildren: './pages/project-list/project-list.module#ProjectListPageModule'
    },
    {
        path: 'edit-user/:id',
        loadChildren: './pages/user-add-edit/user-add-edit.module#UserAddEditPageModule'
    },
    {
        path: 'new-user',
        loadChildren: './pages/user-add-edit/user-add-edit.module#UserAddEditPageModule'
    },
    {
        path: 'user-list',
        loadChildren: './pages/user-list/user-list.module#UserListPageModule'
    },
    {
        path: 'ipsos-home',
        loadChildren: './pages/ipsos-home/ipsos-home.module#IpsosHomePageModule'
    },
    
];

@NgModule({
    // 
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
