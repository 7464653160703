import { Injectable } from '@angular/core';
import { MENU } from 'src/settings/menu';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalService
{
    static spinner;
    static user: any;
    static client: any;
    static typeofstudy: any;
    static subscription: any;
    static ajaxSubscription: any = [];
    static projectId: number;

    static title;
    private static _titleObserver = new Subject();
    static titleObservable = GlobalService._titleObserver.asObservable();

    static changeTitle(newTitle: string)
    {
        let that = this;
        setTimeout(function ()
        {
            that.title = newTitle;
            that._titleObserver.next(newTitle);
        }, 100);
    }

    static getMenuItemByPath(path: string)
    {
        path = path.split('?')[0];

        for (let item of MENU)
        {
            if (item.path == path)
                return item;
            if (item.sub)
            {
                for (let subItem of item.sub)
                {
                    if (subItem.path == path)
                        return subItem;
                }
            }
        }
    }

    static getPageTitleByPath(path: string)
    {
        path = path.split('?')[0];
        let item = this.getMenuItemByPath(path);
        return item.title;
    }

    static unsubscribeAjax()
    {
        for (let i = 0; i < this.ajaxSubscription.length; i++)
        {
            if (this.ajaxSubscription[i])
                this.ajaxSubscription[i].unsubscribe();
        }
        this.ajaxSubscription = [];
    }

    static unsubscribe()
    {
        if (this.subscription)
            this.subscription.unsubscribe();

        this.unsubscribeAjax();
    }
    static getNameCSV(list) {
        if (list && list.length > 0) {
            return list.map(function (k) { return k["Name"] }).join(",");
        }
        else
            return "";
    }
}
