import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Menu } from './interfaces/menu';
import { AuthService } from './providers/auth.service';
import { GlobalService } from './providers/global.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent
{

    public menuItems: Array<Menu>;
    user: any;
    gs = GlobalService;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authservice: AuthService      
    )
    {
        this.menuItems = [
            {
                title: 'Ipsos Home',
                url: '/ipsos-home',
                icon: 'home'
            },{
                title: 'Manage Users',
                url: '/user-list',
                icon: 'people'
            },
            {
                title: 'Logout',
                url: '/login',
                icon: 'log-out'
            }
        ];
        this.initializeApp();
    }

    initializeApp()
    {
        this.authservice.isLoggedin();
        this.platform.ready().then(() =>
        {
            this.statusBar.backgroundColorByHexString('#ffffff');
            this.splashScreen.hide();
        });
    }
}
